import queryString from 'query-string';

import { QUERY_PARAM } from '@commons/constants';
import { sluggify } from '@utils/string';
import { URI_PARAM } from '@shared/constants';
import isString from '@commons/utils/string/isString';
import isObject from '@commons/utils/object/isObject';
import { CHECKOUT_PATH } from '@payments/constants';

export const ROUTES = {
  HOME: '/',
  RESULTS: '/restaurantes',
  PROFILE: '/restaurantes',
  MY_ADDRESSES: '/addresses',
  COURIER: '/courier',
  COURIER_PICKUP_ADDRESS: '/courier/pickup-address',
  COURIER_DROPOFF_ADDRESS: '/courier/dropoff-address',
  COURIER_PACKAGE_DETAILS: '/courier/package-details',
  COURIER_PAYMENT_METHOD: '/courier/payment-method',
  COURIER_PAYMENT_SELECT_CARD: '/courier/payment/cards',
  COURIER_PAYMENT_CARD: '/courier/payment/card',
  COURIER_PAYMENT: '/courier/payment',
  COURIER_TERMS_AND_CONDITIONS: '/courier/terms-and-conditions',
  COURIER_DELIVERY_TIME: '/courier/delivery-time',
  COURIER_WEB_VIEW: '/courier-web-view',
  COURIER_WEB_VIEW_PAYMENT: '/courier-web-view/payment',
  COURIER_WEB_VIEW_PAYMENT_SELECT_CARD: '/courier-web-view/payment/cards',
  COURIER_WEB_VIEW_PAYMENT_CARD: '/courier-web-view/payment/card',
  COURIER_WEB_VIEW_PICKUP_ADDRESS: '/courier-web-view/pickup-address',
  COURIER_WEB_VIEW_DROPOFF_ADDRESS: '/courier-web-view/dropoff-address',
  COURIER_WEB_VIEW_PACKAGE_DETAILS: '/courier-web-view/package-details',
  COURIER_WEB_VIEW_PAYMENT_METHOD: '/courier-web-view/payment-method',
  COURIER_WEB_VIEW_TERMS_AND_CONDITIONS:
    '/courier-web-view/terms-and-conditions',
  COURIER_WEB_VIEW_DELIVERY_TIME: '/courier-web-view/delivery-time',
  COURIER_VOUCHERS: '/courier/vouchers',
  COURIER_WEB_VIEW_VOUCHERS: '/courier-web-view/vouchers',
  COURIER_PHONE_VALIDATION: '/courier/phone-validation',
  COURIER_WEB_VIEW_PHONE_VALIDATION: '/courier-web-view/phone-validation',
  COURIER_WEB_BUSINESS: '/courier-web-business',
  COURIER_WEB_BUSINESS_PAYMENT: '/courier-web-business/payment',
  COURIER_WEB_BUSINESS_PAYMENT_SELECT_CARD:
    '/courier-web-business/payment/cards',
  COURIER_WEB_BUSINESS_PAYMENT_CARD: '/courier-web-business/payment/card',
  COURIER_WEB_BUSINESS_PAYMENT_METHOD: '/courier-web-business/payment-method',
  COURIER_WEB_BUSINESS_PAYMENT_ADD_NEW_CARD:
    '/courier-web-business/payment/add-card',
  COURIER_WEB_BUSINESS_SELECT_CREDIT_DEBIT:
    '/courier-web-business/payment/select-card-type',
  CREATE_ADDRESS: '/addresses/new',
  MY_ACCOUNT: '/my-account',
  MY_ACCOUNT_PHONE_VALIDATION: '/my-account/phone-validation',
  MY_FAVORITES: '/favorites',
  NOTIFICATIONS: '/notifications',
  CART: '/cart',
  CART_PHONE_VALIDATION: '/cart/phone-validation',
  EXTERNAL_LOGIN: '/external-login',
  LOGIN: '/login',
  LOGIN_WITH_EMAIL: '/login/email',
  SIGN_UP: '/sign-up',
  SIGN_UP_EMAIL: '/sign-up/email',
  SIGN_UP_PROFILE: '/sign-up/profile',
  CHECKOUT: '/checkout',
  CHECKOUT_MICROSITE: '/checkout-mfe',
  CHECKOUT_PAYMENT: '/checkout/payment',
  CHECKOUT_ADDRESS: '/checkout/address',
  CHECKOUT_DELIVERY_TYPE: '/checkout/delivery-type',
  CHECKOUT_PHONE: '/checkout/phone',
  CHECKOUT_DELIVERY_TIME: '/checkout/delivery-time',
  CHECKOUT_NOTES: '/checkout/notes',
  CHECKOUT_CREATE_ADDRESS: '/checkout/address/new',
  CHECKOUT_COMPANY_BILLING: '/checkout/companybilling',
  CHECKOUT_SELECT_CREDIT_DEBIT: '/checkout/select-card-type',
  CHECKOUT_DONATION: '/checkout/donation',
  MY_ORDERS_DEPRECATED: '/myOrders',
  MY_ORDERS: '/my-orders', // Don't change because of emails to review order
  NEW_ORDER_REVIEW: '/my-orders',
  CHAT: '/customer-service',
  CATEGORY_LANDING: '/comidas',
  RESTAURANT_GROUP: '/restaurantGroup',
  ABOUT: '/about',
  LOGISTICS: '/logistics',
  ORDER_DETAILS: '/order-details',
  ORDER_STATUS: '/order-state',
  RIDER_REVIEW: '/rider-review',
  CHECKOUT_PAYMENT_SELECT_CARD: '/checkout/payment/cards',
  CHECKOUT_PAYMENT_CARD: '/checkout/payment/card',
  PAYMENT_SELECT_CARD: '/checkout/payment/cards',
  PAYMENT_CARD: '/checkout/payment/card',
  PAYMENT_ADD_NEW_CARD: '/checkout/payment/add-card',
  JOKER: '/joker',
  JOKER_EXPIRED_TIME: '/joker/expiredTime',
  JOKER_EXITE_FLOW_QUESTION: '/joker/exitFlowQuestion',
  JOKER_OFFERS: '/joker/offers',
  LOYALTY: '/loyalty-program',
  LOYALTY_TERMS_AND_CONDITION: '/loyalty/termsAndConditions',
  ABOUT_POLICY: '/about/policy',
  SUGGESTIONS: '/suggestions',
  SUGGESTIONS_WEB_VIEW: '/suggestions-web-view',
  TERMS_AND_CONDITIONS_FILE: '/terms-and-conditions-file',
  NUTRITIO_INFO: '/nutrition-info',
  ABOUT_TC: '/about/terminos-condiciones',
  ABOUT_TYC_GENERALS: '/about/tyc_generals',
  ABOUT_PRIVACY_POLICIES: '/about/privacy_policies',
  ABOUT_TYC_COURIER: '/about/tyc_courier',
  ABOUT_TYC_WALLET: '/about/tyc_wallet',
};

// Parent routes that user must be logged in to access them.
const PROTECTED_ROUTES = [
  ROUTES.MY_ADDRESSES,
  ROUTES.MY_ACCOUNT,
  ROUTES.MY_FAVORITES,
  ROUTES.MY_ORDERS,
  ROUTES.NOTIFICATIONS,
  ROUTES.CHECKOUT,
  ROUTES.LOGISTICS,
  ROUTES.ORDER_DETAIL,
  ROUTES.RIDER_REVIEW,
  ROUTES.COURIER,
];

export function isRouteProtected(route) {
  return isString(route)
    ? PROTECTED_ROUTES.some(r => route.startsWith(r))
    : false;
}

export function getChannelsQuery({ channels = [] }) {
  return channels.join(',');
}

export function getResultsRoute({
  latitude,
  longitude,
  cityName,
  area,
  address,
  channels,
  category,
}) {
  const baseRoute = ROUTES.RESULTS;
  const params = {};

  if (channels) params[QUERY_PARAM.CHANNELS] = getChannelsQuery({ channels });
  if (category) params[QUERY_PARAM.CATEGORY] = category;
  if (latitude && longitude) {
    params[QUERY_PARAM.LATITUDE] = latitude;
    params[QUERY_PARAM.LONGITUDE] = longitude;
  }
  if (cityName) params[QUERY_PARAM.CITY_NAME] = cityName;
  if (area && area.id && area.name) {
    params[QUERY_PARAM.AREA_ID] = area.id;
    params[QUERY_PARAM.AREA_NAME] = area.name || '';
  }
  if (address) params[QUERY_PARAM.ADDRESS] = address;

  const query = queryString.stringify(params);
  return `${baseRoute}?${query}`;
}

export function getLandingRoute({ cityName, channels }) {
  const params = {};

  if (channels) params.channels = getChannelsQuery({ channels });

  const query = queryString.stringify(params);
  const withQuery = query ? `?${query}` : '';

  return `${ROUTES.RESULTS}/${sluggify(cityName)}${withQuery}`;
}

export function getRestaurantGroupRoute({
  latitude,
  longitude,
  restaurantGroup,
}) {
  const params = {};
  if (latitude && longitude) {
    params.lat = latitude;
    params.lng = longitude;
  }
  const query = queryString.stringify(params);
  return `${ROUTES.RESTAURANT_GROUP}/${restaurantGroup}?${query}`;
}

export function getRestaurantGroupRoutePath() {
  return `${ROUTES.RESTAURANT_GROUP}/:restaurantGroup`;
}

export function getRestaurantsPageRegExp() {
  return /\/restaurantes[?/]/;
}

export function getProfilePageRegExp() {
  return /\/restaurantes\/(.+)-menu/;
}

export function getPaymentMethodRoute(id, path = CHECKOUT_PATH) {
  return `${ROUTES[`${path}_PAYMENT`]}/method/${id}`;
}

export function getPaymentMethodRoutePath() {
  return `${ROUTES.CHECKOUT_PAYMENT}/method/:paymentId`;
}

export function getSectionRoute({ city, restaurant, sectionId }) {
  return `${ROUTES.PROFILE}/${city}/${restaurant}-menu/seccion/${sectionId}`;
}

export function getShopDetailsProductConfigurationRoute({ city, restaurant }) {
  return `${ROUTES.PROFILE}/${city}/${restaurant}-menu`;
}

export function getCityLandingRoutePath({ link }) {
  return `${ROUTES.RESULTS}/${sluggify(link)}`;
}

export function getShopDetailsInfoRoutePath() {
  return `${ROUTES.PROFILE}/:${URI_PARAM.CITY}/:${URI_PARAM.SHOP_MENU}/info`;
}

export function getShopDetailsInfoRoute({ city, restaurant }) {
  return `${ROUTES.PROFILE}/${city}/${restaurant}-menu/info`;
}

export function getCartProductConfigurationRoute({ lineId }) {
  return `${ROUTES.CART}/linea/${lineId}`;
}

export function getSectionDetailsRoutePath() {
  return `${ROUTES.PROFILE}/:${URI_PARAM.CITY}/:${URI_PARAM.SHOP_MENU}/seccion/:sectionId`;
}

export function getCartProductConfigurationRoutePath() {
  return `${ROUTES.CART}/linea/:lineId`;
}

export function getShopDetailsMenuSearchRoutePath() {
  return `${ROUTES.PROFILE}/:${URI_PARAM.CITY}/:${URI_PARAM.SHOP_MENU}/buscar`;
}

export function getShopDetailsSearchRoute({ city, restaurant, query }) {
  const queryText = query ? `?${QUERY_PARAM.SEARCH}=${query}` : '';
  return `${ROUTES.PROFILE}/${city}/${restaurant}-menu/buscar${queryText}`;
}

export function getShopReviewsRoutePath() {
  return `${ROUTES.PROFILE}/:${URI_PARAM.CITY}/:${URI_PARAM.SHOP_MENU}/opiniones`;
}

export function getShopReviewsRoute({ city, restaurant }) {
  return `${ROUTES.PROFILE}/${city}/${restaurant}-menu/opiniones`;
}

export function getNutritionInfoRoute(gtin) {
  return `${ROUTES.NUTRITIO_INFO}?gtin=${gtin}`;
}

const shopDetailsRegex = /\/restaurantes\/(.+)-menu/; // Don't finish with $, because child routes should match.
const shopChainHubRegex = /\/cadenas\/(.+)/;
const categoryHubRegex = /\/comidas\/(.+)/;
const citiesHubRegex = /\/restaurantes\/(.+)/;
const productDetailsRegex = /\/restaurantes\/(.+)\/(.+)-menu\/(.+)\/(.+)/;
const foodLandingRegex = /\/comidas/;
const restaurantsLandingRegex = /\/restaurantes/;
const myOrdersRegex = /\/my-orders/;
const courierRegex = /\/courier/;

export const LANDING_TYPE = {
  CITIES: 'cities',
};

// Parent routes that belongs to order flow (need shop, cart, order).
const CONVERSION_ROUTES = [
  getProfilePageRegExp(),
  ROUTES.CART,
  ROUTES.CHECKOUT,
  ROUTES.CART_VOUCHERS,
];

export function isHome(route) {
  return route === ROUTES.HOME;
}

export function isLanding(route, search) {
  return (
    (restaurantsLandingRegex.test(route) &&
      !search.includes(QUERY_PARAM.LATITUDE) &&
      !search.includes(QUERY_PARAM.LONGITUDE)) ||
    foodLandingRegex.test(route)
  );
}

export function typeOfLanding() {
  return LANDING_TYPE.CITIES;
}

export function isShopList(route, search) {
  if (isObject(search)) {
    return Boolean(
      search[QUERY_PARAM.LATITUDE] &&
        search[QUERY_PARAM.LONGITUDE] &&
        route.startsWith(ROUTES.RESULTS),
    );
  }

  return (
    !!search &&
    route.startsWith(ROUTES.RESULTS) &&
    search.includes(QUERY_PARAM.LATITUDE) &&
    search.includes(QUERY_PARAM.LONGITUDE)
  );
}

export function isShopChainHub(route) {
  return shopChainHubRegex.test(route);
}

export function isCategoryHub(route) {
  return categoryHubRegex.test(route);
}

export function isCityHub(route) {
  return citiesHubRegex.test(route);
}

export function isProductDetails(route) {
  return productDetailsRegex.test(route);
}

export function isCart(route = '') {
  return route.startsWith(ROUTES.CART);
}

export function isCheckout(route = '') {
  return route.startsWith(ROUTES.CHECKOUT);
}

export function isUserAccount(route) {
  if (!route) return false;
  return (
    route.startsWith(ROUTES.MY_ADDRESSES) ||
    route.startsWith(ROUTES.MY_ORDERS) ||
    route.startsWith(ROUTES.MY_FAVORITES) ||
    route.startsWith(ROUTES.MY_ACCOUNT)
  );
}

export function isCompanyContent(route) {
  return route.startsWith(ROUTES.ABOUT);
}

export function isShopDetails(route) {
  return shopDetailsRegex.test(route);
}

export function isConversionRoute(route) {
  if (!route) return false;
  return CONVERSION_ROUTES.some(x =>
    x instanceof RegExp
      ? x.test(route)
      : route.startsWith(x) || new RegExp(`${x}/.*`).test(route),
  );
}
export function isCourierRoute(route) {
  if (!route) return false;
  return courierRegex.test(route);
}

export function isMyOrdersRoute(route) {
  return myOrdersRegex.test(route);
}

export function isLinkOfShop(url, shopLink) {
  return url.includes(shopLink);
}

export function isChat(route) {
  return route === ROUTES.CHAT;
}

export function categoryType(location) {
  const { pathname, search } = location;
  let category;
  if (isHome(pathname)) {
    category = 'home';
  } else if (isShopList(pathname, search)) {
    category = 'shop_list';
  } else if (isProductDetails(pathname)) {
    category = 'product_details';
  } else if (isShopDetails(pathname)) {
    category = 'shop_details';
  } else if (isShopChainHub(pathname)) {
    category = 'shop_chain_hub';
  } else if (isCategoryHub(pathname)) {
    category = 'category_hub';
  } else if (isCityHub(pathname)) {
    category = 'city_hub';
  } else if (isCart(pathname, search)) {
    category = 'cart';
  } else if (isCheckout(pathname, search)) {
    category = 'checkout';
  } else if (isUserAccount(pathname, search)) {
    category = 'user_account';
  } else if (isCompanyContent(pathname)) {
    category = 'company_content';
  } else if (isLanding(pathname, search)) {
    category = 'landing';
  } else if (isChat(pathname)) {
    category = 'chat';
  } else if (isCourierRoute(pathname)) {
    category = 'courier';
  } else {
    category = 'other';
  }
  return category;
}

export function getShopDetailsRoute({ cityName, link }) {
  if (isShopDetails(link)) return link;
  return `${ROUTES.RESULTS}/${sluggify(cityName)}/${encodeURI(link)}-menu`;
}

export function isValidationAccountRoute(route) {
  return [
    ROUTES.LOGIN_WITH_EMAIL,
    ROUTES.SIGN_UP_EMAIL,
    ROUTES.SIGN_UP_PROFILE,
  ].some(validRoute => route === validRoute);
}
