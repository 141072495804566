import axios from 'axios';
import { error as logError } from '@logger';

const MARKET_DELAY = 200;
const MARKET_FALLBACK_DELAY = 1000;

export const redirectWindowLocation = url => {
  try {
    if (typeof url !== 'string' || url.trim() === '') {
      throw new Error('Invalid URL: Must be a non-empty string');
    }

    const allowedProtocols = ['https:', 'http:', 'itms-apps:', 'pedidosya:'];
    const trustedDomains = [
      'pedidosya.com',
      'www.pedidosya.com',
      'itunes.apple.com',
      'apps.apple.com',
      'play.google.com',
    ];
    const dangerousProtocols = /^(javascript:|data:|vbscript:|file:|about:)/i;

    const urlObject = new URL(url, window.location.origin);
    const { protocol } = urlObject;
    const { hostname } = urlObject;

    if (
      !!protocol &&
      (!allowedProtocols.includes(protocol) ||
        dangerousProtocols.test(protocol))
    ) {
      throw new Error(`Blocked: Protocol "${protocol}" is not allowed.`);
    }

    if (!!hostname && !trustedDomains.includes(hostname)) {
      throw new Error(`Blocked: Domain "${hostname}" is not trusted.`);
    }

    urlObject.search = new URLSearchParams(urlObject.searchParams).toString();
    window.location.href = urlObject.toString();
  } catch (error) {
    console.error('Redirection error:', error.message);
  }
};

const getIOSLink = (cartId, shopId, userHash, searchedLocation) =>
  `pedidosya://www.pedidosya.com/checkout/start?origin=cart&cart_guid=${cartId}&partner_id=${shopId}&hash=${userHash}&lat=${searchedLocation?.latitude}&long=${searchedLocation?.longitude}`;

// eslint-disable-next-line consistent-return
const copyIOSToClipboard = async iOSLink => {
  try {
    await navigator.clipboard.writeText(iOSLink);
  } catch (error) {
    return error;
  }
};

const openAppOrStoreWithFallback = () => {
  const appStoreIDLink = 'uy/app/pedidosya/id490099807';
  redirectWindowLocation(`https://itunes.apple.com/${appStoreIDLink}`);
};

const goToIOSDeferredLink = async iOSLink => {
  // App not installed
  setTimeout(async () => {
    const result = await copyIOSToClipboard(iOSLink);
    if (!result) openAppOrStoreWithFallback();
  }, MARKET_DELAY);
};

const getAndroidLink = (cartId, shopId) =>
  `pedidosya://checkout/start?origin=cart&cart_guid=${cartId}&partner_id=${shopId}`;

const generateAndroidReferrer = (cartId, shopId, userHash) => {
  const referrer = `deeplink%3Dpedidosya%3A%2F%2Fcheckout%2Fstart%3Forigin%3Dcart%26cart_guid%3D${encodeURIComponent(
    cartId,
  )}%26partner_id%3D${encodeURIComponent(shopId)}%26hash%3D${encodeURIComponent(
    userHash,
  )}`;

  return encodeURIComponent(referrer);
};

const openPlayStoreWithFallback = referrer => {
  const packageName = 'com.pedidosya';
  const fallbackUrl = `https://play.google.com/store/apps/details?id=${packageName}&referrer=${referrer}`;

  const isAndroid = /Android/i.test(navigator.userAgent);

  if (isAndroid) {
    const link = document.createElement('a');
    link.href = `market://details?id=${packageName}&referrer=${referrer}`;
    link.click();

    setTimeout(() => {
      redirectWindowLocation(fallbackUrl);
    }, MARKET_FALLBACK_DELAY);
  } else {
    redirectWindowLocation(fallbackUrl);
  }
};

const goToAndroidDeferredLink = (androidLink, referrer) => {
  // App not installed
  setTimeout(() => {
    openPlayStoreWithFallback(referrer, MARKET_DELAY);
  }, MARKET_DELAY);

  // App installed
  redirectWindowLocation(androidLink);
};

// eslint-disable-next-line consistent-return
export const getUserHash = async () => {
  try {
    const { data: userHash } = await axios.get('/user-hash');
    return userHash;
  } catch (error) {
    logError('[WEB_TO_NATIVE_CHECKOUT]', { error });
  }
};

export const goToAndroidNativeDeferredLink = async ({ cartId, shopId }) => {
  try {
    const userHash = await getUserHash();
    goToAndroidDeferredLink(
      getAndroidLink(cartId, shopId, userHash),
      generateAndroidReferrer(cartId, shopId, userHash),
    );
  } catch (error) {
    logError('[WEB_TO_NATIVE_CHECKOUT]', { error });
  }
};

export const goToIOSNativeDeferredLink = async ({
  cartId,
  shopId,
  searchedLocation,
}) => {
  try {
    const userHash = await getUserHash();
    await goToIOSDeferredLink(
      getIOSLink(cartId, shopId, userHash, searchedLocation),
    );
  } catch (error) {
    logError('[WEB_TO_NATIVE_CHECKOUT]', { error });
  }
};
