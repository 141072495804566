export const OS = {
  ANDROID: 'Android',
  IOS: 'iOS',
  UNKNOWN: 'unknown',
};

export const USER_APPLICATION = {
  ANDROID: 'ANDROID',
  WEB_MOBILE: 'WEB_MOBILE',
  WEB_DESKTOP: 'WEB',
};
