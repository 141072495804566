import { css } from 'styled-components';

export const NAME = {
  MAIN: 'TT Commons Pro',
  SECONDARY: 'TT Commons Pro',
};

const SIZE = {
  XX_SMALL: 11,
  X_SMALL: 12,
  SMALL: 14,
  MEDIUM: 16,
  LARGE: 18,
  X_LARGE: 20,
  XX_LARGE: 24,
};

const WEIGHT = {
  REGULAR: 400,
  SEMI_BOLD: 600,
  BOLD: 700,
  EXTRA_BOLD: 800,
};

const buildFont = (family, size, weight) => css`
  font-family: ${family};
  font-size: ${size}px;
  font-weight: ${weight};
`;

const buildMainFont = (size, weight) => buildFont(NAME.MAIN, size, weight);
const buildSecondaryFont = (size, weight) =>
  buildFont(NAME.SECONDARY, size, weight);

const FONTS = {
  mainFont: {
    xSmallSemiBold: buildMainFont(SIZE.X_SMALL, WEIGHT.SEMI_BOLD),
    xSmallExtraBold: buildMainFont(SIZE.X_SMALL, WEIGHT.EXTRA_BOLD),
    smallSemiBold: buildMainFont(SIZE.SMALL, WEIGHT.SEMI_BOLD),
    smallExtraBold: buildMainFont(SIZE.SMALL, WEIGHT.EXTRA_BOLD),
    mediumSemiBold: buildMainFont(SIZE.MEDIUM, WEIGHT.SEMI_BOLD),
    mediumExtraBold: buildMainFont(SIZE.MEDIUM, WEIGHT.EXTRA_BOLD),
    largeSemiBold: buildMainFont(SIZE.LARGE, WEIGHT.SEMI_BOLD),
    largeBold: buildMainFont(SIZE.LARGE, WEIGHT.BOLD),
    largeExtraBold: buildMainFont(SIZE.LARGE, WEIGHT.EXTRA_BOLD),
    xLargeExtraBold: buildMainFont(SIZE.X_LARGE, WEIGHT.EXTRA_BOLD),
    xxLargeExtraBold: buildMainFont(SIZE.XX_LARGE, WEIGHT.EXTRA_BOLD),
  },
  secondaryFont: {
    xxSmallRegular: buildSecondaryFont(SIZE.XX_SMALL, WEIGHT.REGULAR),
    xxSmallBold: buildSecondaryFont(SIZE.XX_SMALL, WEIGHT.BOLD),
    xSmallRegular: buildSecondaryFont(SIZE.X_SMALL, WEIGHT.REGULAR),
    xSmallBold: buildSecondaryFont(SIZE.X_SMALL, WEIGHT.BOLD),
    smallRegular: buildSecondaryFont(SIZE.SMALL, WEIGHT.REGULAR),
    smallBold: buildSecondaryFont(SIZE.SMALL, WEIGHT.BOLD),
    mediumRegular: buildSecondaryFont(SIZE.MEDIUM, WEIGHT.REGULAR),
    mediumBold: buildSecondaryFont(SIZE.MEDIUM, WEIGHT.BOLD),
    largeRegular: buildSecondaryFont(SIZE.LARGE, WEIGHT.REGULAR),
    largeBold: buildSecondaryFont(SIZE.LARGE, WEIGHT.BOLD),
    xxLargeRegular: buildSecondaryFont(SIZE.XX_LARGE, WEIGHT.REGULAR),
    xxLargeBold: buildSecondaryFont(SIZE.XX_LARGE, WEIGHT.BOLD),
  },
};

export const fonts = {
  mainFont: NAME.MAIN,
  secondaryFont: NAME.SECONDARY,
};
export default FONTS;
