import React from 'react';
import PropTypes from 'prop-types';
import { intlShape } from 'react-intl';
import { Route, Switch } from 'react-router-dom';

import { ROUTES } from '@services/routes.service';
import commonMessages from '@shared/messages';
import { URI_PARAM } from '@shared/constants';

import connect from './connect';
import HomeHeaders from './Home/HomeHeaders';
import ShopDetailsHeaders from './ShopDetails/ShopDetailsHeaders';
import ShopsListHeaders from './ShopList/ShopListHeaders';
import BasicHelmet from './BasicHelmet';

function HeadersManager({ intl: { formatMessage }, store }) {
  return (
    <Switch>
      <Route
        exact
        path={ROUTES.HOME}
        render={() => <HomeHeaders store={store} />}
      />

      <Route exact path={ROUTES.RESULTS} render={() => <ShopsListHeaders />} />

      <Route
        path={`${ROUTES.RESULTS}/:${URI_PARAM.CITY}/:${URI_PARAM.SHOP_MENU}`}
        render={() => <ShopDetailsHeaders />}
      />

      <Route
        exact
        path={ROUTES.CHAT}
        render={() => (
          <BasicHelmet
            title={formatMessage(commonMessages.metaTitle)}
            metaContent={formatMessage(commonMessages.metaDescription)}
            noFollow
          />
        )}
      />

      <Route
        render={() => (
          <BasicHelmet
            title={formatMessage(commonMessages.metaTitle)}
            metaContent={formatMessage(commonMessages.metaDescription)}
          />
        )}
      />
    </Switch>
  );
}

HeadersManager.propTypes = {
  intl: intlShape,
  store: PropTypes.object,
};

HeadersManager.defaultProps = {};

export default connect(HeadersManager);
