import React from 'react';

import PropTypes from 'prop-types';
import useOnce from '@hooks/useOnce';
import PeyaLoader from '@pedidosya/web-fenix/animations/PeyaLoader';

const AuthenticatedHOC = WrappedComponent => {
  const AuthenticatedHOCFunction = ({
    isUserLogged,
    externalLogin,
    ...rest
  }) => {
    useOnce(() => {
      if (!isUserLogged) {
        const { location } = rest;
        const pathname = location?.pathname;
        const search = location?.search;

        externalLogin({ pathname, search });
      }
    });

    if (isUserLogged) {
      return <WrappedComponent {...rest} />;
    }

    return <PeyaLoader position="center" />;
  };

  AuthenticatedHOCFunction.propTypes = {
    isUserLogged: PropTypes.bool.isRequired,
    externalLogin: PropTypes.func.isRequired,
  };

  return AuthenticatedHOCFunction;
};

AuthenticatedHOC.propTypes = {
  WrappedComponent: PropTypes.element,
};

export default AuthenticatedHOC;
