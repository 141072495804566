// eslint-disable-next-line no-unused-vars
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import Modal from '@pedidosya/web-fenix/organisms/Modal';
import Button from '@pedidosya/web-fenix/atoms/Button';
import Loader from '@components/BrandLoader';
import { MODAL_TYPE, TRACKING_ORIGIN } from '@commons/constants';
import getMobileOperatingSystem from '@utils/userAgent/getMobileOS';
import { OS } from '@utils/constants';
import useOnce from '@hooks/useOnce';
import {
  goToAndroidNativeDeferredLink,
  goToIOSNativeDeferredLink,
} from './helpers';

const MODAL_OPEN_DELAY = 10000;
const MODA_CLOSE_DELAY = 60000;

const RedirectQrCheckoutNative = ({ trackModalClosed }) => {
  const [showModal, setShowModal] = useState(false);
  const location = useLocation();

  const params = new URLSearchParams(location.search);
  const origin = params.get('origin') || '';
  const parsedParams = origin.replaceAll('___', '&');
  const paramsURL = new URLSearchParams(parsedParams);

  const cartId = paramsURL.get('cartId');
  const shopId = paramsURL.get('shopId');
  const latitude = paramsURL.get('lat');
  const longitude = paramsURL.get('long');
  const searchedLocation = { latitude, longitude };

  const os = getMobileOperatingSystem();

  const trackNativeCheckout = () => {
    if (typeof trackModalClosed === 'function') {
      trackModalClosed(
        MODAL_TYPE.INSTALL_APP,
        TRACKING_ORIGIN.BUTTON.DOWNLOAD,
        TRACKING_ORIGIN.BUTTON.TYPE,
      );
    }
  };

  const closeWindow = (delay = 0) => {
    setTimeout(() => {
      window.history.back();
      window.open('about:blank', '_self').close();
    }, delay);
  };

  useOnce(() => {
    if (!cartId && !shopId && !latitude && !longitude) {
      closeWindow();
    }

    if (os === OS.ANDROID) {
      goToAndroidNativeDeferredLink({ cartId, shopId, searchedLocation });
    }

    setTimeout(
      () => {
        setShowModal(true);
      },
      os === OS.ANDROID ? MODAL_OPEN_DELAY : 0,
    );

    closeWindow(MODA_CLOSE_DELAY);

    if (os === OS.ANDROID) {
      trackNativeCheckout();
    }
  });

  const handleOnContinueClick = async () => {
    setShowModal(false);

    if (os === OS.ANDROID) {
      goToAndroidNativeDeferredLink({ cartId, shopId });
    }

    if (os === OS.IOS) {
      trackNativeCheckout();
      await goToIOSNativeDeferredLink({ cartId, shopId, searchedLocation });
      setShowModal(false);
      closeWindow(MODAL_OPEN_DELAY);
    }
  };

  const handleOnModalClose = () => {
    handleOnContinueClick();
    closeWindow();
  };

  return (
    <>
      {!showModal ? (
        <Loader width="28px" />
      ) : (
        <Modal
          open={showModal}
          onClose={handleOnModalClose}
          primaryButton={
            <Button
              fullWidth
              label="Continuar"
              size="large"
              onClick={handleOnContinueClick}
            />
          }
          title="👋 Hola! Continúa tu compra en nuestra app"
          text="No te preocupes, el carrito que llenaste está guardado. Solo necesitas descargar nuestra app para continuar justo donde lo dejaste y proceder al pago."
        />
      )}
    </>
  );
};

RedirectQrCheckoutNative.propTypes = {
  trackModalClosed: PropTypes.func.isRequired,
};

export default RedirectQrCheckoutNative;
