import { track, logError } from '@tracking';

const EVENT_NAME = 'modal.closed';

const trackModalClosed = (
  modalType,
  action = null,
  clickLocation = null,
) => () => {
  try {
    track(EVENT_NAME, { modalType, action, clickLocation });
  } catch (error) {
    logError(EVENT_NAME, error);
  }
};

export default trackModalClosed;
