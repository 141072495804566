import { connect } from 'react-redux';
import { compose } from 'redux';
import trackModalClosed from '@tracking/actions/trackModalClosed';

const mapDispatchToProps = {
  trackModalClosed,
};

const withConnect = connect(null, mapDispatchToProps);

export default compose(withConnect);
