import React from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import { IntlProvider } from 'react-intl';
import { ConnectedRouter } from 'connected-react-router/immutable';
import { StaticRouter } from 'react-router-dom';
import ScrollMemory from 'react-router-scroll-memory';
import SkipLink from '@shared/components/SkipLink';
import FenixProvider from '@pedidosya/web-fenix/contexts/FenixContext';
import { getHistory } from '@utils/history';
import ErrorBoundary from '@shared/components/ErrorBoundary';
import HeadersManager from '@shared/containers/HeadersManager/HeadersManager';
import WindowProvider from '@providers/WindowProvider';
import CountryShortNameContext from '@providers/CountryShortNameContext';
import ScreenSizeProvider from '@providers/ScreenSizeProvider';
import TooltipProvider from '@providers/TooltipProvider';
import DeviceProvider from '@providers/DeviceProvider';
import Error from '@shared/containers/Error/Loadable';
import GlobalStyleSheet from './theme/globalStyleSheet';
import MainRouter from './MainRouter';
import theme from './theme';
function AppRouter({ url, history, isServer, children }) {
  if (isServer)
    return (
      <StaticRouter location={url} context={{}}>
        {children}
      </StaticRouter>
    );

  return <ConnectedRouter history={history}>{children}</ConnectedRouter>;
}

AppRouter.propTypes = {
  url: PropTypes.string,
  history: PropTypes.object,
  isServer: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
};

function App({
  lng,
  messages,
  store,
  url,
  isServer,
  device,
  countryShortName,
}) {
  return (
    <IntlProvider locale={lng} defaultLocale="es" key={lng} messages={messages}>
      <Provider store={store}>
        <AppRouter url={url} isServer={isServer} history={getHistory()}>
          <WindowProvider>
            <DeviceProvider serverDevice={device}>
              <ScreenSizeProvider>
                <CountryShortNameContext.Provider value={countryShortName}>
                  <HeadersManager store={store} />
                  <ScrollMemory />
                  <FenixProvider customTheme={theme}>
                    <GlobalStyleSheet />
                    <TooltipProvider>
                      <ErrorBoundary fallback={<Error />}>
                        <SkipLink />
                        <MainRouter />
                      </ErrorBoundary>
                    </TooltipProvider>
                  </FenixProvider>
                </CountryShortNameContext.Provider>
              </ScreenSizeProvider>
            </DeviceProvider>
          </WindowProvider>
        </AppRouter>
      </Provider>
    </IntlProvider>
  );
}

App.defaultProps = {
  url: '/',
  isServer: false,
};

App.propTypes = {
  lng: PropTypes.string.isRequired,
  messages: PropTypes.object.isRequired,
  store: PropTypes.object.isRequired,
  url: PropTypes.string,
  isServer: PropTypes.bool,
  device: PropTypes.string.isRequired,
  countryShortName: PropTypes.string,
};

export default App;
