import { errorAction, successfulAction } from '@shared/actions';
import { ROUTES } from '@services/routes.service';
import { REDIRECT_TO_LOGIN_EXTERNAL } from '../actionTypes';

const type = REDIRECT_TO_LOGIN_EXTERNAL;

const externalLogin = ({ pathname, search = '' }) => async (
  dispatch,
  _,
  axios,
) => {
  try {
    dispatch({ type });

    const pathWithoutSlash =
      !!pathname && pathname.startsWith('/') ? pathname.substr(1) : pathname;
    const EXTERNAL_LOGIN_PARAMS = pathWithoutSlash
      ? `?originPath=${pathWithoutSlash}${search}`
      : '';
    const EXTERNAL_LOGIN_URL = `${ROUTES.EXTERNAL_LOGIN}${EXTERNAL_LOGIN_PARAMS}`;
    const { data } = await axios.get(EXTERNAL_LOGIN_URL);

    if (data && data.redirectUrl) {
      dispatch(successfulAction({ type }));
      window.location.replace(data.redirectUrl);
    }
  } catch (error) {
    dispatch(errorAction({ type, error }));
  }
};

export default externalLogin;
