import React from 'react';
import styled from 'styled-components';

import PeyaLoader from '@pedidosya/web-fenix/animations/PeyaLoader';

const Container = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
`;

function BrandLoader() {
  return (
    <Container>
      <PeyaLoader />
    </Container>
  );
}

export default BrandLoader;
